export const BASE_URL = "https://krushquest.org";//process.env.NEXT_PUBLIC_API_URL;
// export const BASE_URL = "http://localhost:3000";//process.env.NEXT_PUBLIC_API_URL;
// export const BASE_URL = "http://5.39.72.93:57326";//process.env.NEXT_PUBLIC_API_URL
//  export const BASE_URL = "http://94.23.2.132:3000";//process.env.NEXT_PUBLIC_API_URL;
// export const BASE_URL = "https://nestjs-boilerplate-test.herokuapp.com";//process.env.NEXT_PUBLIC_API_URL;
export const API_VERSION = "/api/v1"
export const API_URL = BASE_URL + API_VERSION;//process.env.NEXT_PUBLIC_API_URL;
export const AUTH_REFRESH_URL = API_URL + "/auth/refresh";
export const AUTH_ME_URL = API_URL + "/auth/me";
export const AUTH_LOGOUT_URL = API_URL + "/auth/logout";
