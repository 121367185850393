import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './store'
import { SnackbarProvider } from 'notistack'
// begin add translations
import global_en from "./translations/en/global.json"
import global_fr from "./translations/fr/global.json"
import i18next from 'i18next'
import { I18nextProvider } from 'react-i18next'


i18next.init({
  interpolation: {escapeValue: false},
  lng: "fr",
  resources: {
    en: {
      global: global_en,
    },
    fr: {
      global: global_fr,
    },
  }
})

// end add translations

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <Provider store={store}>
    <SnackbarProvider>
      {/* add I18nextProvider section then add <App /> inside I18nextProvider */}
      <I18nextProvider i18n={i18next}>
        <App />
      </I18nextProvider>
    </SnackbarProvider>
  </Provider>,
)
// Dans ce code <App /> correspond au fichier App.js de notre application
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()


// String Prototype
String.prototype.toTitleCase = function () {
  return this.split(" ").map(txt=>txt.charAt(0).toUpperCase()+txt.substr(1).toLowerCase()).join(" ");
};