var HTTP_CODES_ENUM

;(function(HTTP_CODES_ENUM) {
  HTTP_CODES_ENUM[(HTTP_CODES_ENUM["OK"] = 200)] = "OK"
  HTTP_CODES_ENUM[(HTTP_CODES_ENUM["CREATED"] = 201)] = "CREATED"
  HTTP_CODES_ENUM[(HTTP_CODES_ENUM["ACCEPTED"] = 202)] = "ACCEPTED"
  HTTP_CODES_ENUM[(HTTP_CODES_ENUM["NO_CONTENT"] = 204)] = "NO_CONTENT"
  HTTP_CODES_ENUM[(HTTP_CODES_ENUM["BAD_REQUEST"] = 400)] = "BAD_REQUEST"
  HTTP_CODES_ENUM[(HTTP_CODES_ENUM["UNAUTHORIZED"] = 401)] = "UNAUTHORIZED"
  HTTP_CODES_ENUM[(HTTP_CODES_ENUM["FORBIDDEN"] = 403)] = "FORBIDDEN"
  HTTP_CODES_ENUM[(HTTP_CODES_ENUM["NOT_FOUND"] = 404)] = "NOT_FOUND"
  HTTP_CODES_ENUM[(HTTP_CODES_ENUM["UNPROCESSABLE_ENTITY"] = 422)] =
    "UNPROCESSABLE_ENTITY"
  HTTP_CODES_ENUM[(HTTP_CODES_ENUM["INTERNAL_SERVER_ERROR"] = 500)] =
    "INTERNAL_SERVER_ERROR"
  HTTP_CODES_ENUM[(HTTP_CODES_ENUM["SERVICE_UNAVAILABLE"] = 503)] =
    "SERVICE_UNAVAILABLE"
  HTTP_CODES_ENUM[(HTTP_CODES_ENUM["GATEWAY_TIMEOUT"] = 504)] =
    "GATEWAY_TIMEOUT"
})(HTTP_CODES_ENUM || (HTTP_CODES_ENUM = {}))

export default HTTP_CODES_ENUM
