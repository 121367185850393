import React, { CContainer, Component, Suspense } from 'react'
import { HashRouter, Route, Routes, Navigate } from 'react-router-dom';
import QueryClientProvider from './services/react-query/query-client-provider'
import queryClient from './services/react-query/query-client'
import ReactQueryDevtools from './services/react-query/react-query-devtools'
import './scss/style.scss'
import AuthProvider from './services/auth/auth-provider'
import useAuth from './services/auth/use-auth';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
const AdminLayout = React.lazy(() => import('./layout/AdminLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const ConfirmEmail = React.lazy(() => import('./views/pages/confirm_email/ConfirmEmail'))
// const Register = React.lazy(() => import('./views/pages/register/Register'))
// const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
// const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

const App = () => {

  return (
    <QueryClientProvider client={queryClient} >
      <ReactQueryDevtools initialIsOpen={false} />
      <AuthProvider>
        <HashRouter>
          <Suspense fallback={loading}>
            <Routes>
              <Route exact path="/login" name="Login" element={<Login />} />
              <Route exact path="/confirm-email" name="ConfirmEmail" element={<ConfirmEmail />} />
              {/* <Route exact path="/register" name="Register Page" element={<Register />} /> */}
              {/* <Route exact path="/404" name="Page 404" element={<Page404 />} /> */}
              {/* <Route exact path="/500" name="Page 500" element={<Page500 />} /> */}
              {/* <Route path="/apps/email/*" name="Email App" element={<EmailApp />} /> */}
              {/* <Route path="*" name="Home" element={<AdminLayout />} /> */}
              <Route exact path="/admin/*" name="Admin" element={<AdminLayout />} />
              <Route exact path="/app/*" name="App" element={<DefaultLayout />} />
              <Route exact path="*" element={<Navigate to="login" replace />} />
            </Routes>
          </Suspense>
        </HashRouter>
      </AuthProvider>
    </QueryClientProvider>
  )
}

export default App
